$viewber_blue: #032855;
$viewber_light_blue: #b7c4ff;
$turquoise: #edf4fa;
$hover_blue: #20a8d8;
$active_nav_item: #2f353a;
$read_background_color: #f0f3f5;
$read_color: #5c6873;

BODY {
	background-color: $turquoise;
	font-size: 1rem;
}

.bg-viewber {
	background-color: $viewber_blue;
}

.heading-blue {
	color: $viewber_blue;
}

.viewber-blue {
	background-color: $viewber_blue;
}

.hover-blue {
	color: $hover_blue;
}

.viewber-light-blue {
	background-color: $viewber_light_blue;
}

.color-black {
    color: #000000;
}

.half-offset {
	margin-left:4.166666667%;
}

.app-footer {
	flex: none;

	DIV {
		font-size: 0.75rem;
	}
}

.rdtTimeToggle {
	background-color: $hover_blue;
}

#nprogress {
	.bar {
		background: $hover_blue !important;

		 height: 5px !important;
	}

	.peg {
		display: none !important;
	}

	.spinner {
		display: none !important;
	}
}

@import "app-header";
@import "sidebar";
@import "pagination";
@import "notifications";
@import "page-title";
@import "placeholder-shimmer";
@import "card-header-actions";
@import "dropzone";
@import "buttons";

.list-group-item {
	div {
		padding-left: 5px;
		padding-right: 5px;
	}

	&.list-group-item-action.not-read {

	}

	&.list-group-item-action.read {
	    color: $read_color;
	    text-decoration: none;
	    background-color: $read_background_color;
	}
}

.listPagination {
	margin-top: 20px;
}

.btn-group {
	.btn-block + .btn-block {
		margin-top: 0;
	}
}

IMG.img-loading {
	opacity: 0.5;
}

.star-rating {
	.fa-star {
		color: $hover_blue;
	}
}

.sort-dropdown-menu {
	min-width: 100%;
	text-align: center;
}

.notification-dropdown-header {
	background: white;
}

.notification-dropdown-item {
	white-space: normal;

	&.read {
		background: $read_background_color;
		color: $read_color !important;
	}

	&.not-read {

	}
}

@media only screen and (max-width: 480px) {
	.app-header .nav-item {
		min-width: 40px;

		.nav-link > .img-avatar, .app-header .nav-item .avatar.nav-link > img {
			margin: 0 20px;
		}
	}

	.main .container-fluid {
		padding: 0 10px;
	}

	.breadcrumb {
		margin-bottom: 1rem;
	}
}

@media (min-width: 576px) {
	.card-group.viewing-card-group {
		.card {
			border-radius: 0;
		}

		&:first-of-type {
			.card:first-child {
				border-top-left-radius: 0.25rem;
			}
			.card:last-child {
				border-top-right-radius: 0.25rem;
			}
		}
		&:last-of-type {
			.card:first-child {
				border-bottom-left-radius: 0.25rem;
			}
			.card:last-child {
				border-bottom-right-radius: 0.25rem;
			}
		}
	}
}
